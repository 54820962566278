import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./Articles.module.scss";
import { Page } from "src/components/GobalElement";
import styled from "@emotion/styled";
import IconEco from "../Articles/images/new-icon.png";
import { ImageContent } from "./KnowingEcoSticker";
import { BoxDigitalEconomy } from "./FlowReuestEcoSticker";

const MobileApplication = () => {
	return (
		<Page title="Mobile Application">
			<div className={styles.pageContent}>
				<Container>
					<Stack spacing={4}>
						<Typography
							textAlign={"center"}
							variant="span"
							fontSize={48}
							fontWeight={400}
							color={"#008000"}
						>
							Mobile Application
						</Typography>
						<Stack spacing={3} direction={"column"} justifyContent={"center"} alignItems={"center"}>
							<ImageContent
								sx={{
                                    overflow:'hidden',
									borderRadius: 20,
                                    backgroundColor:'#aaa',
									boxShadow:
										"rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
								}}
								width={270}
							>
								<img src={IconEco} />
							</ImageContent>
							<Stack spacing={3}>
								<BoxDigitalEconomy>
									<Typography fontSize={18} fontWeight={400} variant="p">
										Car Search Application
									</Typography>
								</BoxDigitalEconomy>
								<Typography sx={{ textIndent: "50px" }} variant="span" fontSize={18} fontWeight={400}>
									สำหรับผู้บริโภค เพื่อใช้เปรียบเทียบคุณสมบัติของรถยนต์ในด้าน “สะอาด ประหยัด ปลอดภัย”
									สำหรับประกอบการตัดสินใจเลือกซื้อ
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Container>
			</div>
		</Page>
	);
};

export default MobileApplication;
